import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { AppCommon, GkToastType } from '@modules/app-common/models';
import { UserService } from '@modules/auth/services';
import { clamp } from '@modules/utils';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmDialogOptions, DialogService } from './dialog.service';
import { ToastService } from './toast.service';
import { Default } from '@modules/config/models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmacionMComponent } from '@modules/customers/components';

@Injectable({ providedIn: 'root' })
export class AppCommonService {

    private spinnerActive = false;
    private API_URL_COMMONS = environment.api.commons;
    private API_URL_ADMINCATALOGS = environment.api.administracionCatalogs;
    readonly toasts$ = this.toastService.toasts$;
    private activeRequestsSubject = new BehaviorSubject<number>(0);
    showLoader$ = this.activeRequestsSubject.asObservable().pipe(map(activeRequests => activeRequests > 0));
    private dialog = inject(MatDialog);

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private toastService: ToastService,
        private dialogService: DialogService,
    ) {

    }



    Notify(textOrTpl: string, type: GkToastType = 'success') {
        this.toastService.show(textOrTpl, type);
    }

    spinner(run: boolean) {
        let activeRequests = this.activeRequestsSubject.value;

        if (run) {
            activeRequests += 1;
        } else {
            activeRequests -= 1;
        }
        // console.log('spiner',activeRequests);
        activeRequests = clamp(activeRequests, 0, Infinity);
        this.activeRequestsSubject.next(activeRequests);

        // Mostrar u ocultar el spinner según el número de solicitudes activas
        if (activeRequests > 0 && !this.spinnerActive) {
            $("html").css("cursor","wait");
            this.spinnerActive = true;
            this.showSpinner();
        } else if (activeRequests === 0 && this.spinnerActive) {
            $("html").css("cursor","initial");
            this.spinnerActive = false;
            this.hideSpinner();
        }
    }

    private showSpinner() {
        // Lógica para mostrar el spinner
        //console.log('Spinner activado');
    }

    private hideSpinner() {
        // Lógica para ocultar el spinner
        //console.log('Spinner desactivado');
    }

    async sendEmail(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .post<AppCommon>(`${this.API_URL_COMMONS}/email/send`, requestBody, {
                    headers,
                })
                .subscribe(resp => {
                    console.info(environment.api.commons);
                    console.info('RESPONSE ERROR: ', resp);
                    if (resp.status == 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getListPaises() {
        return new Promise((resolve, reject) => {
            this.http
                .get<AppCommon>(`${this.API_URL_ADMINCATALOGS}/paises`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }
    async ListEstados() {
        return new Promise((resolve, reject) => {

            this.http
                .get<AppCommon>(`${this.API_URL_ADMINCATALOGS}/estados`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });

    }
    async ListCiudades () {
        return new Promise((resolve, reject) => {
            this.http
                .get<AppCommon>(`${this.API_URL_ADMINCATALOGS}/ciudades`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    openDialog<TResult, TData = unknown, TComponent = unknown>(component: TComponent, options: NgbModalOptions & { data?: TData } = {}) {
        return this.dialogService.open<TResult, TData, TComponent>(component, options);
    }

    openConfirmDialog(options: { type: ConfirmDialogOptions['type']; title: string; message: string }) {
        if (!options.message) {
            options.message = 'Do you really want to ... ?';
        }

        return this.dialogService.confirm(options).catch(error => error);
    }

    PDFViewer(file: string, type = 'pdf') {
        this.dialogService.viewFile(file, type);
    }

    UrlViewer(file: string, type = 'urlpdf') {
        this.dialogService.viewUrl(file, type);
    }

    ViewModalFilter(dataRecollecion: any, visor: any) {
        this.dialogService.viewModalFilter(dataRecollecion, visor);
    }

    ViewModalFilterDx(dataRecollecion: any, visor: any) {
        this.dialogService.viewModalFilterDx(dataRecollecion, visor);
    }

    uploadFile(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_COMMONS}/file`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    getFile(requestBody){
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_COMMONS}/getfile`, requestBody, { headers })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });          
        });        
    }

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let result: any = reader.result;
                result = result.split(',')[1];
                resolve(result);
            };
            reader.onerror = error => reject(error);
        });
    }

    downloadBase64FileToBrowser(fileType, base64, fileName){
        const src = `data:${fileType};base64,${base64}`;
        const link = document.createElement("a");
        link.href = src;
        link.download = fileName;
        link.click();
        link.remove();
    }

    getSum(array: any[], field: string){
        return array.reduce((accumulator :number, currentValue :any) => accumulator + currentValue[field],0);
    }

    IsDefined(param: any) {
        return param !== undefined && param != null;
    }

    async ApiCall(callback: () => any){
        try{
            this.spinner(true);
            await callback().finally(()=> this.spinner(false));
        }catch(err){
            this.spinner(false);
            console.error(err);
            this.Notify("Ocurrio un error, favor de revisar la consola", 'error');
        }
    }


    showMsg(titulo:string , mensaje: string, callback?: () => void){
        const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: titulo,
                mensaje: mensaje,
                Noconfirm: true
            }
        });
        dialogRefM.afterOpened().subscribe(() => {
            setTimeout(() => {
                dialogRefM.close();
            }, 5000);
        });
        dialogRefM.afterClosed().subscribe(() => {
            if (callback) {
                callback();
            }
        });
    }

    showMsgConfirm(titulo:string , mensaje: string): Observable<boolean> {
        const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: titulo, mensaje: mensaje }
        })

        return dialogRef.afterClosed();
    }

    getFileExtension(fileName: string){
        return fileName.substring(fileName.lastIndexOf(".")+1);
    }

}
