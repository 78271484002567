import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import { AppCommonService } from '@modules/app-common/services';
import { ReportsService } from '@modules/reports/services';
import { UserService } from '@modules/auth/services';

@Component({
    selector: 'gk-sample-capture-filter-dx',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sample-capture-filter-dx.component.html',
    styleUrls: ['sample-capture-filter-dx.component.scss'],
})
export class SampleCaptureFilterDxComponent implements OnInit {
    @Input() dataRecollecion: any=[];
    @Input() visor: any;
    @Input() Language
    @Input() visorSimple: any;

    bitVisor: any;
    clasePadding: any;
    formFiltros: FormGroup 
    DatosVisor: any;
    user: any  = btoa('ngUsrGK')
    pass: any  = btoa('ngGenomi-k2023!.123')
    urlBase:any='https://sistema.genomi-k.com/';
    today = new Date();
    timeDefaultValue = setHours(new Date(), 0);
    readonly searchType = [
        {"id": 0, "value": '0', "text":'Seleccionar'},
        {"id": 1, "value": 'M', "text":'Masculino'},
        {"id": 2, "value": 'F', "text":'Femenino'},
        {"id": 3, "value": 'AS', "text":'Ambigüo'},
        {"id": 4, "value": 'NR', "text":'No registrado'},
    ];

    readonly searchRaza = [
        {"id": 0, "value": '0', "text":'Seleccionar'},
        {"id": 1, "value": '1', "text":'Caucásico / Europeo'},
        {"id": 2, "value": '2', "text":'Afroamericano'},
        {"id": 3, "value": '3', "text":'Asiática'},
        {"id": 4, "value": '4', "text":'Mestiza'},
        {"id": 6, "value": '5', "text":'Judío Askenazí'},
        {"id": 5, "value": '6', "text":'Otro'},
    ];

    readonly searchMoneda = [
        {"id": 0, "value": '', "text":'Seleccionar'},
        {"id": 1, "value": '1', "text":'Pesos'},
        {"id": 2, "value": '0', "text":'Moneda de la propuesta'},
    ];
   
    DataCiudades: any;
    DataEstados: any;
    DataPaises: any;
    idPaisMe: any;
    idEstadoMe: any;
    DataCiudadesMe: any;
    DataEstadosMe: any;
    idPais: any;
    DataEstadosM: any;
    idEstado: any;
    DataCiudadesM: any;
    verDatosMadre: any;
    archivosArray: any;
    isCliente: any = 0;
    claseLabel:any=''
    URLImagen: any = '';

    @Output()
    propagar = new EventEmitter<boolean>();
    Filtro_Id: any;
    informacionPruebaArray: any;
    DataComentarios: any;

    range(start: number, end: number): number[] {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
        result.push(i);
        }
        return result;
    }

    constructor(
        public formBuilder: FormBuilder,
        public appCommon: AppCommonService,
        private ref: ChangeDetectorRef,
        private reportsService:ReportsService,
        public userService: UserService,
    ) {
        this.formFiltros = formBuilder.group({
            nombre: [''],
            Apellidos: [''],
            fechaNac: [''],
            sexo: [''],
            fechaNacHorario: [''],
            raza: [''],
            
            mamanombre: [''],
            mamaApellidos: [''],
            mamadir: [''],
            paismama: [''],
            estadomama: [''],
            ciudadmama: [''],
            telefonomama: [''],
            correomama: [''],
            
            mediconombre: [''],
            medicoApellidos: [''],
            medicodir: [''],
            paismedico: [''],
            estadomedico: [''],
            ciudadmedico: [''],
            telefono: [''],
            correo: [''],

            descripcion: [''],
            antecedente: [''],
            estudiosrealizados: [''],
            impresiondiagnostica: [''],

            monedafactura: [''],
            transfusion: [''],
            fechaTrans: [''],

            medicamento1: [''],
            medicamento2: [''],
            medicamento3: [''],
            medicamento4: [''],
            medicamento5: [''],
            medicamento6: [''],

            fechaMues: [''],
            fechaMuesHorario: [''],
            mpre: [''],

            chkTM1: [''],
            chkTM2: [''],
            chkTM3: [''],
            chkTM4: [''],
            chkTM5: [''],
            chkTM6: [''],
            chkTM7: [''],
            chkTM8: [''],
            chkTM9: [''],
            chkTM10: [''],
            chkTM11: [''],
            chkTM12: [''],
        })

    }
    ngOnInit() {
        if(this.visor == true){
            this.dataRecollecion = this.dataRecollecion
        }
        if(location.hostname.toLowerCase().indexOf("localhost") != -1) {
            this.urlBase = "https://demo.genomi-k.com/"
        }
        this.getIsCliente();

        this.DatosVisor = this.dataRecollecion.DatosVisor       
        this.bitVisor = this.dataRecollecion.bitVisor
        if(this.bitVisor == 1){
            this.clasePadding = 'no-padding'
        }      
        this.cargarPaises(this.dataRecollecion.arrayPaises);
        this.cargarEstados(this.dataRecollecion.arrayEstados);
        this.cargarCiudades(this.dataRecollecion.arrayCiudades);
        
        this.CargaDatos()
    }

    async MostrarImgPF(){
        this.appCommon.UrlViewer(this.URLImagen,'urlimg')
    }

    getIsCliente(){
        return new Promise((resolve, reject) => {
            this.userService.isCliente$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    this.isCliente = data
                    if(!this.isCliente){
                        this.claseLabel = 'redlabel'
                    }            
                }
            });
        });
    }

    async generarFiltro(form:any){
        if(form.value.nombre.trim() == ''){
            this.appCommon.Notify("Favor de introducir el nombre del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.Apellidos.trim() == ''){
            this.appCommon.Notify("Favor de introducir los apellidos del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.fechaNac == ''){
            this.appCommon.Notify("Escriba la fecha de nacimiento del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.fechaNacHorario == ''){
            this.appCommon.Notify("Seleccione si es AM o PM la fecha de nacimiento del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.fechaNacHorario == ''){
            this.appCommon.Notify("Seleccione si es AM o PM la fecha de nacimiento del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.fechaMues == ''){
            this.appCommon.Notify("Escriba la fecha en que fue tomada la prueba", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.transfusion == ''){
            this.appCommon.Notify("Seleccione si fue transfundido o no", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.transfusion == '1' && form.value.fechaTrans == ''){
            this.appCommon.Notify("Escriba la fecha en que fue transfundido", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.fechaMuesHorario == ''){
            this.appCommon.Notify("Seleccione si es AM o PM la fecha de nacimiento del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }

        if(form.value.mpre == ''){
            this.appCommon.Notify("Seleccione si la muestra es para estudio prenatal", 'warning');
            this.ref.detectChanges();
            return false;
        }


        let hoy = new Date()
        let fechaT = new Date(this.formFiltros.controls.fechaMues.value)
        let fechaN = new Date(this.formFiltros.controls.fechaNac.value)
        let diferencia = fechaT.getTime() - fechaN.getTime() ;
        let diferencia2 = hoy.getTime() - fechaN.getTime() ;
        let horaNac = fechaN.toLocaleTimeString();
        let horaMuestra = fechaT.toLocaleTimeString();


        if(fechaT > fechaN){
            this.appCommon.Notify("Fecha de muestra debe ser posterior a la fecha de nacimiento", 'warning');
            this.ref.detectChanges();
            return false;
        }

        if(fechaT > hoy){
            this.appCommon.Notify("Fecha de la toma debe ser menor a la fecha de hoy", 'warning');
            this.ref.detectChanges();
            return false;
        }

        if(form.value.raza == ''){
            this.appCommon.Notify("Favor de seleccionar la raza", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(this.verDatosMadre == '1'){
            if(form.value.correomama == ''){
                this.appCommon.Notify("Escriba el Email de la madre", 'warning');
                this.ref.detectChanges();
                return false;
            }
        }
        if(form.value.paismedico == ''){
            this.appCommon.Notify("El pais del médico no ha sido seleccionado", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.estadomedico == ''){
            this.appCommon.Notify("El estado del médico no ha sido seleccionado", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.ciudadmedico == ''){
            this.appCommon.Notify("La ciudad del médico no ha sido seleccionado", 'warning');
            this.ref.detectChanges();
            return false;
        }


        alert('guaerda')

    }

    disabledDate = (current: Date): boolean =>
        // Can not select days before today and today
            differenceInCalendarDays(current, this.today) > 0;
    
    disabledDateTime: DisabledTimeFn = () => ({
        nzDisabledHours: () => this.range(0, 24).splice(4, 20),
        nzDisabledMinutes: () => this.range(30, 60),
        nzDisabledSeconds: () => [55, 56]
    });
    
    disabledRangeTime: DisabledTimeFn = (_value, type?: DisabledTimePartial) => {
        if (type === 'start') {
        return {
            nzDisabledHours: () => this.range(0, 60).splice(4, 20),
            nzDisabledMinutes: () => this.range(30, 60),
            nzDisabledSeconds: () => [55, 56]
        };
        }
        return {
        nzDisabledHours: () => this.range(0, 60).splice(20, 4),
        nzDisabledMinutes: () => this.range(0, 31),
        nzDisabledSeconds: () => [55, 56]
        };
    };    

    cargarPaises(array){
        this.DataPaises =  array;
    }

    cargarEstados(array){
        this.DataEstadosM =  array;
        this.DataEstadosMe =  array;
    }

    cargarCiudades(array){
        this.DataCiudadesM = array; 
        this.DataCiudadesMe = array; 
    }

    CargaDatos(){
        if(this.dataRecollecion.length != 0){
            const Secciones = this.dataRecollecion.Secciones
            this.Filtro_Id = this.dataRecollecion.Filtro_id
            const comentarios = Secciones.dataComentarios
            this.DataComentarios = {
                Medicos: comentarios.Medicos,
                Operativos: comentarios.Operativos,
                Calificacion: comentarios.Calificacion,
                usuario: comentarios.usuario,
                Paciente_id: Secciones.Datos.pxid,
                Filtro_Id: this.Filtro_Id,
            }
    
            this.verDatosMadre = Secciones.Datos.verDatosMadre
            this.formFiltros.patchValue({
                nombre: Secciones.Datos.nbebe,
                Apellidos: Secciones.Datos.apbebe,
                fechaNac: Secciones.Datos.fechaNac,
                sexo: Secciones.Datos.Sexo,
                fechaNacHorario: Secciones.Datos.nampm,
                raza: (Secciones.Datos.raza).toString(),
                /* DATOS MADRE */
                mamanombre: Secciones.Datos.mamanombre,
                mamaoApellidos: Secciones.Datos.mamaapellido,
                mamadir: Secciones.Datos.mamadir,
                paismama: Secciones.Datos.paismama,
                estadomama: Secciones.Datos.estadomama,
                ciudadmama: Secciones.Datos.ciudadmama,
                telefonomama: Secciones.Datos.mamatel,
                correomama: Secciones.Datos.mamacorreo,

                /* DATOS MEDICO */
                mediconombre: Secciones.Datos.nmedico,
                medicoApellidos: Secciones.Datos.apmedico,
                medicodir: Secciones.Datos.medicodir,
                paismedico: Secciones.Datos.paismedico,
                estadomedico: Secciones.Datos.estadomedico,
                ciudadmedico: Secciones.Datos.ciudadmedico,
                telefono: Secciones.Datos.medicotel,
                correo: Secciones.Datos.medicocorreo,

                descripcion: Secciones.Desc_Padecimiento,
                antecedente: Secciones.Antecedente_Padecimiento,
                estudiosrealizados: Secciones.EstudiosRealizados,
                impresiondiagnostica: Secciones.Impresion_Diagnostica,
                transfusion: Secciones.Datos.transfusion.toString(),

                medicamento1: Secciones.Medicamentos.medicamento1,
                medicamento2: Secciones.Medicamentos.medicamento2,
                medicamento3: Secciones.Medicamentos.medicamento3,
                medicamento4: Secciones.Medicamentos.medicamento4,
                medicamento5: Secciones.Medicamentos.medicamento5,
                medicamento6: Secciones.Medicamentos.medicamento6,
                fechaTrans: Secciones.Datos.fechatras,
                fechaMues: Secciones.Datos.fechaMues,
                fechaMuesHorario: Secciones.Datos.nampm,
                mpre: Secciones.Datos.mpre.toString(),

                chkTM1: Secciones.TipoMuestraForma.chkTM1,
                chkTM2: Secciones.TipoMuestraForma.chkTM2,
                chkTM3: Secciones.TipoMuestraForma.chkTM3,
                chkTM4: Secciones.TipoMuestraForma.chkTM4,
                chkTM5: Secciones.TipoMuestraForma.chkTM5,
                chkTM6: Secciones.TipoMuestraForma.chkTM6,
                chkTM7: Secciones.TipoMuestraForma.chkTM7,
                chkTM8: Secciones.TipoMuestraForma.chkTM8,
                chkTM9: Secciones.TipoMuestraForma.chkTM9,
                chkTM10: Secciones.TipoMuestraForma.chkTM10,
                chkTM11: Secciones.TipoMuestraForma.chkTM11,
                chkTM12: Secciones.TipoMuestraForma.chkTM12,
            });    
            this.archivosArray = Secciones.Archivos
            this.informacionPruebaArray = Secciones.InformacionPrueba
        }
    }
    
    onCheckChangeBusqueda($event) {       
        this.idPais = $event
        this.DataEstadosM =  this.DataEstados.filter(estado => estado.Pais_id == this.idPais)
        this.formFiltros.controls.estadomama.setValue('');
    }
    onCheckChangeBusqueda2($event) {       
        this.idEstado = $event
        this.DataCiudadesM =  this.DataCiudades.filter(ciudad => ciudad.Estado_id == this.idEstado)
        this.formFiltros.controls.ciudadmama.setValue('');
    }

    onCheckChangeBusqueda3($event) {       
        this.idPaisMe = $event
        this.DataEstadosMe =  this.DataEstadosMe.filter(estado => estado.Pais_id == this.idPaisMe)
        this.formFiltros.controls.estadomedico.setValue('');
    }

    onCheckChangeBusqueda4($event) {       
        this.idEstadoMe = $event
        this.DataCiudadesMe =  this.DataCiudades.filter(ciudad => ciudad.Estado_id == this.idEstadoMe)
        this.formFiltros.controls.ciudadmedico.setValue('');
    }

    limpiaFechaTrans(){
        this.formFiltros.controls.fechaTrans.setValue('');
    }

    
    async RegresarFiltro(){
        const requestBody = {
            "filtroid": this.Filtro_Id
        }
        
        this.appCommon.spinner(true);
        this.reportsService
        .RegresarFiltro(requestBody)
            .then(
                (Default:any) => {
                    this.ref.detectChanges();
                    this.propagar.emit(true);
                    this.appCommon.spinner(false);
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'danger');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.appCommon.Notify(e, 'danger');
                this.ref.detectChanges();
            });
    }


}
