import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'gk-modal-view-filter-dx',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'modal-view-filter-dx.component.html',
    styleUrls: ['modal-view-filter-dx.component.scss'],
})
export class ModalViewFilterDxComponent implements OnInit {
    @Input() datoRecollecion: any=[];
    @Input() visor: any;
    
    constructor() {}
    ngOnInit() {}
}
